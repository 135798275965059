<template>
  <div class="box-card">
    <!-- 车牌识别相机 -->
    <div>
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-form-item label="停车场：" v-if="this.getUserInfo.user_name === 'admin'">
          <el-select popper-class="my-select" v-model="queryForm.parking_id" filterable clearable default-first-option
            placeholder="请选择停车场" @change="onSubmit">
            <el-option v-for="item in getAllParkingList(2)" :key="item.parking_id" :label="item.parking_name"
              :value="item.parking_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="序列号：">
          <el-input v-model="queryForm.device_serial" maxlength="30" @keyup.enter.native="onSubmit"
            @input="(e) => (queryForm.device_serial = validFacility(e))" placeholder="请输入序列号"></el-input>
        </el-form-item>
        <el-form-item label="设备名称：">
          <el-input v-model="queryForm.device_name" maxlength="30" @keyup.enter.native="onSubmit"
            @input="(e) => (queryForm.device_name = validSpace(e))" placeholder="请输入设备名称"></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select popper-class="my-select" placeholder="请选择设备状态" clearable v-model="queryForm.device_state"
            @change="onSubmit" @clear="queryForm.device_state = null">
            <!-- <el-option label="全部" :value="0"></el-option> -->
            <el-option label="在线" :value="1"></el-option>
            <el-option label="离线" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="车牌识别相机">
        <template slot="heard-right">
          <div class="right">
            <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('990b5a28b0fa4c34b3b5545b') ||
              isShowBtn('da75fe88868f4efcb6730856')
              ">删除</lbButton>
            <lbButton icon="xinzeng" @click="openAddForm()" v-if="this.getUserInfo.user_name != 'admin' &&
              (isShowBtn('1360f064d0fa4f0bbf268f28') ||
                isShowBtn('ebee2c68889a44fc8033a049'))
              ">新增</lbButton>
          </div>
        </template>
        <!-- 主要内容 -->
        <div style="text-align: left">
          <!-- 表格 -->
          <!-- 使用插件生产表格 -->
          <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
            @size-change="sizeChange" @current-change="currentChange">
            <template slot-scope="{row}" slot="device_state">
              <div :style="row.device_state == 2
                ? 'color: #ff0000;'
                : 'color: #18943b;'
                ">
                {{ row.device_state == 2 ? "离线" : "在线" }}
              </div>
            </template>
            <template slot-scope="{row}" slot="parking_name">
              <div :style="row.parking_name != '未配置' ? '' : 'color: #aaa;'
                ">
                {{ row.parking_name }}
              </div>
            </template>
            <template slot-scope="{row}" slot="create_time">
              {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookCamera(row._id)"></lbButton>
              <lbButton type="warning" icon="bianji" hint="编辑" @click="openEditForm(row)" v-if="isShowBtn('4c3a09570e4e45299002d6fc') ||
                isShowBtn('6ea8038b69434cb3a042c874')
                "></lbButton>
            </template>
          </avue-crud> -->
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
            height="600" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
            <el-table-column prop="identify_camera_serial" label="序列号"></el-table-column>
            <el-table-column prop="identify_camera_name" label="设备名称" min-width="100"></el-table-column>
            <el-table-column prop="identify_camera_brand_name" label="设备品牌" min-width="100"></el-table-column>
            <el-table-column prop="identify_camera_model_name" label="设备型号" min-width="100"></el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <div :style="scope.row.device_state == 2
                  ? 'color: #ff0000;'
                  : 'color: #18943b;'
                  ">
                  {{ scope.row.device_state == 2 ? "离线" : "在线" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="停车场" v-if="this.getUserInfo.user_name === 'admin'" min-width="150">
              <template slot-scope="scope">
                <div :style="scope.row.parking_name != '未配置' ? '' : 'color: #aaa;'
                  ">
                  {{ scope.row.parking_name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" min-width="130">
              <template slot-scope="scope">
                {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="160">
              <template slot-scope="scope">
                <lbButton type="succes" icon="chakan" hint="查看" @click="lookCamera(scope.row._id)"></lbButton>
                <lbButton type="warning" icon="bianji" hint="编辑" @click="openEditForm(scope.row)" v-if="isShowBtn('4c3a09570e4e45299002d6fc') ||
                  isShowBtn('6ea8038b69434cb3a042c874')
                  "></lbButton>
                    <lbButton type="setting" icon="peizhi2" hint="显示屏配置" @click="settingLedScreen(scope.row)" v-if="scope.row.displayid"></lbButton>
                    <!-- v-if="isShowBtn('000f155e2e674b0964597984') ||
                  isShowBtn('c61cf45000c21c7ca7598585')
                  " -->
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </le-card>

    </div>
    <!-- 新增对话框 -->
    <lebo-dialog append-to-body :title="addForm._id ? '编辑设备' : '新增设备'" :isShow="isAddDialog" width="35%"
      @close="closeAddDialogVisible()" footerSlot>
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="120px" class="demo-ruleForm">
        <el-form-item label="设备名称：" prop="identify_camera_name">
          <el-input v-model="addForm.identify_camera_name" placeholder="请输入设备名称" maxlength="30"
            @input="(e) => (addForm.identify_camera_name = validSpace(e))"></el-input>
        </el-form-item>
        <el-form-item label="设备品牌：" prop="identify_camera_brand_name">
          <el-select popper-class="my-select" v-model="addForm.identify_camera_brand_name" placeholder="请选择设备品牌"
            @change="brandChange">
            <el-option v-for="(item, index) in brandList" :key="index" :label="item.brand_name"
              :value="item.brand_name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备型号：" prop="identify_camera_model_name">
          <el-select popper-class="my-select" v-model="addForm.identify_camera_model_name" placeholder="请选择设备型号"
            @change="modelChange">
            <el-option v-for="(item, index) in modelList" :key="index" :label="item.model_name"
              :value="item.model_name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="序列号：" prop="identify_camera_serial">
          <el-input v-model="addForm.identify_camera_serial" maxlength="30" placeholder="请输入序列号"
            @input="(e) => (addForm.identify_camera_serial = validFacility(e))"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="isAddDialog = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="submitAddForm()" v-preventReClick>保 存</lbButton>
      </span>
    </lebo-dialog>
    <!-- 查看设备 -->
    <lebo-dialog :isShow="showLookMachine" width="50%" title="查看设备" :footerSlot="true" @close="showLookMachine = false" closeOnClickModal>
      <lookCamera :id="dialogCameraId" v-if="showLookMachine"></lookCamera>
    </lebo-dialog>
     <!-- 设备设置 -->
     <lebo-dialog :isShow="showLayoutLedScreen" width="60%" title="显示屏配置" :footerSlot="true"
      @close="showLayoutLedScreen = false">
      <ledScreemLayout :id="dialogLedScreen" :name="dialogLedParkName" v-if="showLayoutLedScreen"
        @closeDialog="closeLedSreenDialog"></ledScreemLayout>
    </lebo-dialog>
  </div>
</template>
<script>
import {
  getIdentifyCameraList,
  addIdentifyCamera,
  updateIdentifyCamera,
  delIdentifyCamera,
  getBrandList,
  getModelList
} from '@/api/deviceApi'
import { mapState, mapGetters, mapMutations } from 'vuex'
import lookCamera from './components/lookCamara/index.vue'
import ledScreemLayout from './components/ledScreenLayout/index.vue'

export default {
  components: { lookCamera, ledScreemLayout },
  data () {
    return {
      // 查询表单
      queryForm: {
        parking_id: '',
        PageSize: 10,
        PageIndex: 1,
        device_name: '',
        device_serial: '',
        device_state: null
      },
      total: 0,
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      // 是否展示新增或编辑对话框
      isAddDialog: false,
      // 新增表单
      addForm: {
        _id: '',
        identify_camera_brand_id: '', // 设备品牌id
        identify_camera_brand_name: '', // 设备品牌名称
        identify_camera_model_id: '', // 设备型号id
        identify_camera_model_name: '', // 设备型号名称
        identify_camera_model_pic: '', // 设备型号图片地址
        identify_camera_name: '', // 设备名称
        identify_camera_serial: '' // 设备序列号
      },
      // 新增表单验证
      addFormRules: {
        identify_camera_name: [
          { required: true, message: '请输入设备名称', trigger: 'blur' }
        ],
        identify_camera_brand_name: [
          { required: true, message: '请选择设备品牌', trigger: 'blur' }
        ],
        identify_camera_model_name: [
          { required: true, message: '请选择设备型号', trigger: 'blur' }
        ],
        identify_camera_serial: [
          { required: true, message: '请输入序列号', trigger: 'blur' }
        ]
      },
      // 设备品牌下拉列
      brandList: [],
      // 设备型号下拉列
      modelList: [],
      showLookMachine: false, // 查看设备
      dialogCameraId: '',
      showLayoutLedScreen: false, // 配置显示屏
      dialogLedScreen: '', // 显示屏id
      dialogLedParkName: '显示屏',
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '序列号',
            prop: 'identify_camera_serial'
          }, {
            label: '设备名称',
            prop: 'identify_camera_name'
          }, {
            label: '设备品牌',
            prop: 'identify_camera_brand_name'
          }, {
            label: '设备型号',
            prop: 'identify_camera_model_name'
          }, {
            label: '状态',
            prop: 'device_state',
            slot: true
          }, {
            label: '停车场',
            prop: 'parking_name',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fnGetIdentifyCameraList()
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  mounted () {
    window.addEventListener('beforeunload', (e) => this.beforeunloadFn(e))
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus', 'parkingList']),
    ...mapState('menuList', ['isRoutW']),
    ...mapGetters(['getUserInfo', 'getOptions', 'getWayName', 'getAllParkingList'])
  },
  destroyed () {
    window.removeEventListener('beforeunload', (e) => this.beforeunloadFn(e))
  },
  watch: {},
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    async fnGetIdentifyCameraList () {
      const res = await getIdentifyCameraList({
        parking_id: this.queryForm.parking_id,
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex,
        device_name: this.queryForm.device_name,
        device_serial: this.queryForm.device_serial,
        device_state: this.queryForm.device_state
          ? this.queryForm.device_state
          : null
      })
      console.log(res)
      this.tableData =
        res && res.Code === 200 && res.Data ? res.Data.DataList : []
      this.total =
        res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetIdentifyCameraList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      this.queryForm.PageSize = val
      this.fnGetIdentifyCameraList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetIdentifyCameraList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetIdentifyCameraList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetIdentifyCameraList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的车牌识别相机, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            var obj = {}
            var arr = []
            for (var i = 0; i < this.multipleSelection.length; i++) {
              arr.push(this.multipleSelection[i]._id)
              if (arr.length === this.multipleSelection.length) {
                obj._ids = arr
                console.log(obj)
                this.fnDelIdentifyCamera(obj)
              }
            }
          })
          .catch(() => {
            this.$msg.info('已取消删除')
          })
      } else {
        this.$msg.warning('请选择要删除的车牌识别相机！')
      }
    },
    async fnDelIdentifyCamera (ids) {
      const res = await delIdentifyCamera(ids)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetIdentifyCameraList()
    },
    // 打开新增设备对话框
    openAddForm () {
      this.addForm._id = ''
      this.addForm.identify_camera_brand_id = ''
      this.addForm.identify_camera_brand_name = ''
      this.addForm.identify_camera_model_id = ''
      this.addForm.identify_camera_model_name = ''
      this.addForm.identify_camera_model_pic = ''
      this.addForm.identify_camera_name = ''
      this.addForm.identify_camera_serial = ''
      this.brandList = []
      this.modelList = []
      this.isAddDialog = true
      this.fnGetBrandList()
    },
    // 打开修改设备对话框
    openEditForm (row) {
      console.log(row)
      this.isAddDialog = true
      this.fnGetBrandList()
      this.fnGetModelList(row.identify_camera_brand_id)

      this.addForm._id = row._id
      this.addForm.identify_camera_brand_id = row.identify_camera_brand_id
      this.addForm.identify_camera_brand_name =
        row.identify_camera_brand_name
      this.addForm.identify_camera_model_id = row.identify_camera_model_id
      this.addForm.identify_camera_model_name =
        row.identify_camera_model_name
      this.addForm.identify_camera_model_pic = row.identify_camera_model_pic
      this.addForm.identify_camera_name = row.identify_camera_name
      this.addForm.identify_camera_serial = row.identify_camera_serial
    },
    // 设备品牌下拉框
    async fnGetBrandList () {
      const res = await getBrandList({
        //  1:视频桩 2:地磁 3:高位相机 4:路牙机 5:车牌识别相机 6:显示屏 7:一体机
        device_type: 5
      })
      // console.log(res);
      this.brandList = res && res.Code === 200 ? res.Data : []
    },
    // 当设备品牌下拉框发生改变时
    brandChange (val) {
      // console.log(val);
      var index = this.brandList.findIndex((item) => item.brand_name === val)
      this.addForm.identify_camera_brand_id = this.brandList[index]._id
      this.fnGetModelList(this.brandList[index]._id)
      this.addForm.identify_camera_model_id = ''
      this.addForm.identify_camera_model_name = ''
      this.addForm.identify_camera_model_pic = ''
    },
    // 设备型号下拉框
    async fnGetModelList (id) {
      const res = await getModelList({
        brand_id: id,
        //  1:视频桩 2:地磁 3:高位相机 4:路牙机 5:车牌识别相机 6:显示屏 7:一体机
        device_type: 5
      })
      // console.log(res);
      this.modelList = res && res.Code === 200 ? res.Data : []
    },
    // 当设备型号下拉框发生改变时
    modelChange (val) {
      // console.log(val);
      var index = this.modelList.findIndex((item) => item.model_name === val)
      this.addForm.identify_camera_model_id = this.modelList[index]._id
      this.addForm.identify_camera_model_pic = this.modelList[index].pic
    },
    // 关闭新增编辑对话框，重置数据
    closeAddDialogVisible () {
      this.isAddDialog = false
      // 移除校验结果
      this.$refs.addFormRef.resetFields()
      this.addForm._id = ''
      this.addForm.identify_camera_brand_id = ''
      this.addForm.identify_camera_brand_name = ''
      this.addForm.identify_camera_model_id = ''
      this.addForm.identify_camera_model_name = ''
      this.addForm.identify_camera_model_pic = ''
      this.addForm.identify_camera_name = ''
      this.addForm.identify_camera_serial = ''
      this.brandList = []
      this.modelList = []
      console.log(this.addForm)
    },
    // 新增/编辑
    submitAddForm () {
      // 对整个表单进行校验
      this.$refs.addFormRef.validate((valid) => {
        // 成功
        if (valid) {
          var obj = {
            identify_camera_brand_id: this.addForm.identify_camera_brand_id,
            identify_camera_brand_name: this.addForm.identify_camera_brand_name,
            identify_camera_model_id: this.addForm.identify_camera_model_id,
            identify_camera_model_name: this.addForm.identify_camera_model_name,
            identify_camera_model_pic: this.addForm.identify_camera_model_pic,
            identify_camera_name: this.addForm.identify_camera_name,
            identify_camera_serial: this.addForm.identify_camera_serial
          }
          if (this.addForm._id) {
            obj._id = this.addForm._id
            // console.log(obj);
            this.fnUpdateIdentifyCamera(obj)
          } else {
            this.fnAddIdentifyCamera(obj)
          }
        } else {
          return false
        }
      })
    },
    // 新增车牌识别相机设备请求
    async fnAddIdentifyCamera (obj) {
      // this.isAddDialog = false
      const res = await addIdentifyCamera(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        this.isAddDialog = false
        this.onSubmit()
      }
    },
    // 编辑车牌识别相机设备请求
    async fnUpdateIdentifyCamera (obj) {
      const res = await updateIdentifyCamera(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        this.isAddDialog = false
        this.fnGetIdentifyCameraList()
      }
    },
    // 查看设备
    lookCamera (id) {
      this.showLookMachine = true
      this.dialogCameraId = id
    },
    // 设置显示屏
    settingLedScreen (row) {
      this.dialogLedScreen = row.displayid
      this.showLayoutLedScreen = true
    },
    closeLedSreenDialog () {
      this.showLayoutLedScreen = false
      this.fnGetIdentifyCameraList()
    }
  }
}
</script>
<style scoped lang="less">
.box-card {
  margin-top: 20px;
}

.el-select {
  width: 100%;
}
</style>
